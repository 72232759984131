import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from './components/Header';
import Footer from './components/Footer';
import { useParams } from 'react-router-dom';
// import './Details.css';
import { Helmet } from 'react-helmet';

function Details() {
  const { slug } = useParams(); // Get slug from the URL

  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);



  const h1 = {
     fontSize:'25px'
  };

  
  const h2 = {
    fontSize:'17px'
 };

 
  const h3 = {
  fontSize:'14px'
 };


  useEffect(() => {
    const fetchBlogDetails = async () => {
      try {
        const response = await axios.get(`https://admin.clovefinedine.com/api/blogsDetails/${slug}`);
        setBlog(response.data[0]);
      } catch (err) {
        setError(err.message || 'Error fetching blog details');
      } finally {
        setLoading(false);
      }
    };
    window.scrollTo(0, 0);

    fetchBlogDetails();
  }, [slug]);

  return (
    <div className="page-wrapper">
      <Header />

      {blog && (
        <Helmet>
          <title>{blog.meta_title}</title>
          <meta name="description" content={blog.meta_description} />
          <link rel="canonical" href={`https://clovefinedine.com/${slug}`} />
        </Helmet>
      )}

      <section
        className="page-banner-area overlay pt-215 rpt-150 pb-160 rpb-120 rel z-1 bgs-cover text-center"
        style={{ backgroundImage: "url(assets/images/background/ContactUs.jpg)" }}
      >
        <div className="container">
          <div className="banner-inner text-white">
            <h1
              className="page-title"
              data-aos="fade-up"
              data-aos-duration={1500}
              data-aos-offset={50}
            >
              Blogs
            </h1>
            <nav aria-label="breadcrumb">
              <ol
                className="breadcrumb justify-content-center"
                data-aos="fade-up"
                data-aos-delay={200}
                data-aos-duration={1500}
                data-aos-offset={50}
              >
                <li className="breadcrumb-item">
                  <a href="/">Home</a>
                </li>
                <li className="breadcrumb-item active">Blog Detail</li>
              </ol>
            </nav>
          </div>
        </div>
      </section>

      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}
      {blog && (
        <div style={{ padding: '50px' }}>
          <h1>{blog.title}</h1>
          <div dangerouslySetInnerHTML={{ __html: blog.details }} />
          {/* Render other blog details as needed */}
        </div>
      )}

      <Footer />
    </div>
  );
}

export default Details;
