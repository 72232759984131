import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import $ from 'jquery';
import React, { useState, useEffect } from 'react';

import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Header = () => {

  const loadScript = (src, callback) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = callback;
    script.onerror = () => console.error(`Failed to load script: ${src}`);
    document.head.appendChild(script);
  };

    // State to manage the visibility of the search form
    const [showForm, setShowForm] = useState(false);

    // Function to toggle the search form
    const handleSearchClick = () => {
      setShowForm(!showForm);
    };

  
  useEffect(() => {




    // Check if the sidebar menu exists
    if ($('.menu-sidebar').length) {
        // Show Form
        const showForm = () => {
            $('body').toggleClass('side-content-visible');
        };
        $('.menu-sidebar').on('click', showForm);

        // Hide Form
        const hideForm = () => {
            $('body').removeClass('side-content-visible');
        };
        $('.hidden-bar .inner-box .cross-icon,.form-back-drop,.close-menu').on('click', hideForm);

        // Dropdown Menu
        const toggleDropdown = function () {
            $(this).next('ul').slideToggle(500);
        };
        $('.fullscreen-menu .navigation li.dropdown > a').on('click', toggleDropdown);


        
          
          // List of scripts to load
          const scripts = [
            '/assets/js/scriptaaa.js'
          ];

          // Load each script
          scripts.forEach((src, index) => {
            loadScript(src, () => {
              console.log(`Script loaded: ${src}`);
              // Optionally handle initialization here
            });
          });



        // Cleanup event listeners on component unmount
        return () => {
            $('.menu-sidebar').off('click', showForm);
            $('.hidden-bar .inner-box .cross-icon,.form-back-drop,.close-menu').off('click', hideForm);
            $('.fullscreen-menu .navigation li.dropdown > a').off('click', toggleDropdown);
        };
    }
}, []);


const [formData, setFormData] = useState({
  name: '',
  email: '',
  message: ''
});

const handleChange = (e) => {
  const { name, value } = e.target;
  setFormData({ ...formData, [name]: value });
};

const handleSubmit = async (e) => {
  e.preventDefault();

  try {
    await axios.post('https://your-api-endpoint.com/contact', formData);
    toast.success('Appointment request sent successfully!');
    setFormData({ name: '', email: '', message: '' });
  } catch (error) {
    toast.success('Thank You For Contacting Us');
  }
};


  return (
    <>
    <header className="main-header white-menu menu-absolute">
      <div className="header-upper">
        <div className="container-fluid clearfix">
          <div className="header-inner rel d-flex align-items-center">
            <div className="logo-outer">
              <div className="logo">
                <Link to='/'>
                {/* <h2 className='text-white'>Clove</h2> */}
                <img src="assets/images/new/clovelogo.png"  />
                </Link>
              </div>
            </div>
            <div className="nav-outer ms-lg-5 ps-xxl-4 clearfix">
              <nav className="main-menu navbar-expand-lg">
                <div className="navbar-header py-10">
                  <div className="mobile-logo">
                    <Link to='/'>
                    {/* <h2 className='text-white'>Clove</h2>  */}
                    <img src="assets/images/new/clovelogo.png" />
                    </Link>
                  </div>
                  <button
                    type="button"
                    className="navbar-toggle"
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse"
                  >
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                    <span className="icon-bar" />
                  </button>
                </div>
                <div className="navbar-collapse collapse clearfix">
                  <ul className="navigation clearfix">
                     <li style={{ letterSpacing:'1px' }}>
                      <Link to='/pollachi'>Home</Link>
                    </li>
                    <li style={{ letterSpacing:'1px' }}>
                    <Link to='/reservation'>Reservation</Link>
                    </li>
                    <li style={{ letterSpacing:'1px' }}>
                    <Link to='/history'>Our History</Link>
                    </li>
                    <li style={{ letterSpacing:'1px' }}>
                    <Link to='/menu'>Menu</Link>
                    </li>
                    <li style={{ letterSpacing:'1px' }}>
                    <Link to='/gallery'>Gallery</Link>
                    </li>
                    <li style={{ letterSpacing:'1px' }}>
                    <Link to='/contact'>Contact Us</Link>
                    </li>   
                  </ul>
                </div>
              </nav>
            </div>
            <div className="header-number">
              <i className="far fa-phone" />
              Call : <a href="callto:+919043555270">+91 90435 55270</a>
            </div>
            <div className="nav-search py-10">
                {/* Search button to toggle form visibility */}
                <button
                  className="far fa-search"
                  onClick={handleSearchClick} // Toggle form visibility
                />
                
                {/* Search form */}
                {showForm && ( // Render form only if showForm is true
                  <form action="/menu" className="search-form">
                    <input
                      type="text"
                      placeholder="Search"
                      className="searchbox"
                      required
                    />
                    <button type="submit" className="searchbutton far fa-search" />
                  </form>
                )}
              </div>
            <div className="menu-btns">
              <button>
                {/* <i className="far fa-shopping-cart" />  */}
              </button>
              <Link to="/contact" className="theme-btn">
                Book now <i className="far fa-arrow-alt-right" />
              </Link>
              <div className="menu-sidebar">
                <button className="bg-transparent" />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-lines">
          <span />
          <span />
          <span />
          <span />
        </div>
      </div>
    </header>

    
    <div className="form-back-drop" />
      <section className="hidden-bar">
        <div className="inner-box text-center">
          <div className="cross-icon">
            <span className="fa fa-times" />
          </div>
          <div className="title">
            <h4>Contact Us</h4>
          </div>
          <div className="appointment-form">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Name"
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email Address"
                  required
                />
              </div>
              <div className="form-group">
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Message"
                  rows={5}
                  required
                />
              </div>
              <div className="form-group">
                <button type="submit" className="theme-btn style-two">
                  Submit now
                </button>
              </div>
            </form>
          </div>
          <div className="social-style-one">
            <a >
              <i className="fab fa-twitter" />
            </a>
            <a >
              <i className="fab fa-facebook-f" />
            </a>
            <a >
              <i className="fab fa-instagram" />
            </a>
            <a>
              <i className="fab fa-pinterest-p" />
            </a>
          </div>
        </div>
      </section>
      <ToastContainer position="top-left" />


  </>
  
  );
};

export default Header;
