import Header from './components/Header';
import Footer from './components/Footer';
import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Import the CSS
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { useState } from 'react';
import { Helmet } from 'react-helmet';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: '',
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrors({}); // Reset errors

    // toast.success(formData.message);

    
    toast.success('Thank you Contact Us');

    // try {
    //   const response = await axios.post('https://your-api-endpoint.com/submit', formData);
    //   toast.success(response.data.message);
    //   setFormData({
    //     name: '',
    //     email: '',
    //     phone: '',
    //     subject: '',
    //     message: '',
    //   });
    // } catch (err) {
    //   toast.error('An error occurred. Please try again.');
    // }


  };

  const loadScript = (src, callback) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = callback;
    script.onerror = () => console.error(`Failed to load script: ${src}`);
    document.head.appendChild(script);
  };

  useEffect(() => {
    // List of scripts to load
    const scripts = [
     
      '/assets/js/scriptaaa.js'
    ];

    // Load each script
    scripts.forEach((src, index) => {
      loadScript(src, () => {
        console.log(`Script loaded: ${src}`);
        // Optionally handle initialization here
      });
    });
    window.scrollTo(0, 0);

    // Cleanup function (if needed)
    return () => {
      // Optionally clean up if necessary
    };
  }, []);


  return (
<div className="page-wrapper">
  <Header/>
  <Helmet>
      <title>Contact Clove Restaurant - Your Gateway to Culinary Delights</title>
      <meta name="description" content='Reach out to Clove, Pollachi top restaurant, for reservations, inquiries, and more. We are here to ensure your dining experience is exceptional.' />
      <link rel="canonical" href="https://clovefinedine.com/contact" />
    </Helmet>
  {/*Form Back Drop*/}
  <div className="form-back-drop" />
  {/* Hidden Sidebar */}
 
  <section
    className="page-banner-area overlay pt-215 rpt-150 pb-160 rpb-120 rel z-1 bgs-cover text-center"
    style={{ backgroundImage: "url(assets/images/background/ContactUs.jpg)" }}
  >
    <div className="container">
      <div className="banner-inner text-white">
        <h1
          className="page-title"
          data-aos="fade-up"
          data-aos-duration={1500}
          data-aos-offset={50}
        >
          Contact us
        </h1>
        <nav aria-label="breadcrumb">
          <ol
            className="breadcrumb justify-content-center"
            data-aos="fade-up"
            data-aos-delay={200}
            data-aos-duration={1500}
            data-aos-offset={50}
          >
            <li className="breadcrumb-item">
              <a>Home</a>
            </li>
            <li className="breadcrumb-item active">Contact us</li>
          </ol>
        </nav>
      </div>
    </div>
  </section>
  {/* Page Banner End */}
  {/* Contact Area start */}
  <section className="contact-page-area pt-130 rpt-100 pb-115 rpb-85 rel z-1">
    <div className="container">
      <div className="row mb-130 rmb-100 align-items-center">
        <div className="col-lg-5">
          <div
            className="contact-page-content rmb-55"
            data-aos="fade-left"
            data-aos-duration={1500}
            data-aos-offset={50}
          >
            <div className="section-title mb-35">
              <span className="sub-title mb-10">contact us</span>
              <h2>ready to contact us</h2>
            </div>
            <div className="contact-info-wrap">
              <div className="contact-info-item">
                <div className="icon">
                  <i className="fal fa-map-marker-alt" />
                </div>
                <div className="content">
                  <span className="title">Locations</span>
                  {/* <h6> */}
                  Clove Restaurant, No.27 Nehru Street, A Block B Colony, Opp.Water tank, Mahalingapuram,<br></br> Pollachi -642002
                  {/* </h6> */}
                </div>
              </div>
              <div className="contact-info-item">
                <div className="icon">
                  <i className="fal fa-envelope-open" />
                </div>
                <div className="content">
                  <span className="title">Email Address</span>
                  {/* <h6> */}
                    <a target='_blank' href="mailto:contactus@cloudrestaurant.com">
                      contactus@cloudrestaurant.com
                    </a>
                  {/* </h6> */}
                </div>
              </div>
              <div className="contact-info-item">
                <div className="icon">
                  <i className="fal fa-phone" />
                </div>
                <div className="content">
                  <span className="title">Phone No</span>
                  {/* <h6> */}
                    <a href="callto:+919043555270">+91 90435 55270</a>
                  {/* </h6> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-7"
          data-aos="fade-right"
          data-aos-duration={1500}
          data-aos-offset={50}
        >
          <div className="contact-page-form">
            <h3>Send Us Message</h3>
            <p>
              Your email address will not be published. Required fields are
              marked *
            </p>
            <form onSubmit={handleSubmit}>
          <div className="row mt-30 gap-20">
          <div className="col-sm-6">
            <div className="form-group">
              <input
                name="name"
                type="text"
                placeholder="Please enter your Name"
                required
                value={formData.name}
                onChange={handleChange}
              />
              {errors.name && <div className="help-block with-errors">{errors.name}</div>}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <input
                name="email"
                type="email"
                placeholder="Please enter your Email"
                required
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && <div className="help-block with-errors">{errors.email}</div>}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <input
                name="phone"
                type="text"
                placeholder="Please enter your Phone No"
                required
                value={formData.phone}
                onChange={handleChange}
              />
              {errors.phone && <div className="help-block with-errors">{errors.phone}</div>}
            </div>
          </div>
          <div className="col-sm-6">
            <div className="form-group">
              <input
                name="subject"
                type="text"
                placeholder="Please enter your Subject"
                required
                value={formData.subject}
                onChange={handleChange}
              />
              {errors.subject && <div className="help-block with-errors">{errors.subject}</div>}
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <textarea
                name="message"
                id="message"
                className="form-control"
                rows={4}
                placeholder="Write Message"
                required
                value={formData.message}
                onChange={handleChange}
              />
              {errors.message && <div className="help-block with-errors">{errors.message}</div>}
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group mb-0">
              <button type="submit" className="theme-btn">
                Send Message <i className="far fa-arrow-alt-right" />
              </button>
            </div>
          </div>
        </div>
      </form>
          </div>
        </div>
      </div>
      <div
        className="our-location"
        data-aos="fade-up"
        data-aos-duration={1500}
        data-aos-offset={50}
      >
       
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3920.8585891045705!2d77.01230667408605!3d10.668082561184736!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba837b29cfeda15%3A0x2c4b8db5a9d9b3cb!2sClove%20Fine%20Dine%20Restaurant!5e0!3m2!1sen!2s!4v1724485181806!5m2!1sen!2s" 
         style={{ border: 0, width: "100%" }}
         allowFullScreen="" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
    <div className="bg-lines">
      <span />
      <span />
      <span />
      <span />
    </div>
  </section>
  <Footer/>
</div>


  
  );
}

export default Contact;
