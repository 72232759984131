import React from 'react';

const AutoDate = () => {
  const currentDate = new Date();
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  const formattedDate = currentDate.toLocaleDateString('en-US', options);

  return (
    <li>
      <a>{formattedDate}</a>
    </li>
  );
};

export default AutoDate;
