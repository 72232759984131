import Header from './components/Header';
import Footer from './components/Footer';
import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { Helmet } from 'react-helmet';


function About() {

  const [aboutData, setAboutData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const loadScript = (src, callback) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = callback;
    script.onerror = () => console.error(`Failed to load script: ${src}`);
    document.head.appendChild(script);
  };

  useEffect(() => {


    // Fetch data from API using axios
    const fetchData = async () => {
      try {
        const response = await axios.get('https://admin.clovefinedine.com/api/about');
        setAboutData(response.data[0]);
     
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    // List of scripts to load
    const scripts = [
     
      '/assets/js/scriptaaa.js'
    ];

    // Load each script
    scripts.forEach((src, index) => {
      loadScript(src, () => {
        console.log(`Script loaded: ${src}`);
        // Optionally handle initialization here
      });
    });
    window.scrollTo(0, 0);
    // Cleanup function (if needed)
    return () => {
      // Optionally clean up if necessary
    };
  }, []);



  return (
    <>
    <div className="page-wrapper">
       <Header/>
            <Helmet>
              <title>Clove Restaurant - Discover Our Locations in Pollachi and Coimbatore, India</title>
              <meta name="description" content='Explore Clove Restaurant with locations in Pollachi and Coimbatore, India. Experience a unique blend of tradition and innovation with our diverse menu, crafted from the freshest ingredients.' />
              <link rel="canonical" href="https://clovefinedine.com/about" />
            </Helmet>
    
      <section
        className="page-banner-area overlay pt-215 rpt-150 pb-160 rpb-120 rel z-1 bgs-cover text-center"
        style={{ backgroundImage: "url(assets/images/background/AboutUs.jpg)" }}
      >
        <div className="container">
          <div className="banner-inner text-white">
            <h1 
              className="page-title"
              data-aos="fade-up"
              data-aos-duration={1500}
              data-aos-offset={50}
            >
              Reservation
            </h1>
            <nav aria-label="breadcrumb">
              <ol
                className="breadcrumb justify-content-center"
                data-aos="fade-up"
                data-aos-delay={200}
                data-aos-duration={1500}
                data-aos-offset={50}
              >
                <li className="breadcrumb-item">
                  <a>Home</a>
                </li>
                <li className="breadcrumb-item active">Reservation</li>
              </ol>
            </nav>
          </div>
        </div>
      </section>
      {/* Page Banner End */}
      {/* About Us Area start */}
      <section className="about-us-area-four pt-130 rpt-100 pb-85 rpb-55 rel z-1">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <div
                className="about-us-content ms-0 rmb-25"
                data-aos="fade-left"
                data-aos-duration={1500}
                data-aos-offset={50}
              >
             
                    <div className="section-title mb-25">
                      {/* <span className="sub-title mb-5">Reservation Policy:</span> */}
                      {/* <h2>rgregre</h2> */}
                      <h1 style={{fontSize:'55px'}}>Reservation Policy</h1>
                      <br></br>
                          <p>We do welcome walk-in guests and will accommodate them as much as possible until you book the entire restaurant's seating.</p>

                          <ul>
                              <li><strong>1. Open all seven days:</strong>
                                  <ul style={{padding:'20px'}}>
                                      <li><strong>Lunch:</strong> 12:00 PM to 4:00 PM (Last order to be placed by 3:30 PM)</li>
                                      <li><strong>Dinner:</strong> 7:00 PM to 11:00 PM (Last order to be placed by 10:30 PM)</li>
                                  </ul>
                              </li>
                              <li><strong>2. Reservations are accepted by phone</strong>. If you would like to make a reservation, please get in touch with our team at <a href="tel:+919043555270">+91 90435 55270</a>. Sometimes, our team may have to call you back to confirm details about your reservation. Please understand that this ensures we deliver the best experience possible and maintain proper table spacing for the requested day and time.</li>
                              <li><strong>3. The reservation timing policy:</strong> Given the restaurant's limited seating, we encourage all diners to arrive on time for their reservation. If there is no prior intimation, we can only hold your table for half an hour after the reservation. Please understand this is solely due to the restaurant's size. We cannot guarantee a table if you have missed your initial reservation. However, if you are late, we urge you to contact us so we can make our best effort to accommodate you and your guests.</li>
                              <li><strong>4. Cancellation policy:</strong> Due to limited seating at the restaurant, we encourage all our diners to keep us informed about any change to their reservation. If you decide to cancel your reservation, please call us at <a href="tel:+919043555270">+91 90435 55270</a>, and we can arrange your table for other diners.</li>
                          </ul>
                            <br></br>
                            <h2>Please review us:</h2> 
                            <p>
                          
                            We’re so glad you enjoyed your meal! If you have a moment, we’d appreciate it if you could leave us a review on Google to let others know about your experience.
                          </p>
                          <br></br>
                          <h2>Catering Confirmation Policy</h2>
                          <p>Thank you for choosing Clove for your catering needs! To ensure a smooth and successful event, please review our catering confirmation policy below:</p>

                          <ul>
                              <li><strong>1. Reservation Confirmation:</strong> We require a non-refundable deposit of 50% of the estimated cost to secure your catering date. This deposit will be applied to your final bill.</li>
                              <li><strong>2. Final Guest Count and Menu Selections:</strong> The final guest count and menu selections must be confirmed at least one day before your event. This count will be the guaranteed minimum number of guests for which you will be billed.</li>
                              <li><strong>3. Payment Terms:</strong> The remaining balance should be paid on the day of the event. Payments can be made via credit card, UPI, or cash.</li>
                              <li><strong>4. Cancellations:</strong> Cancellations made on the day of the event will not be refunded with the deposit amount.</li>
                              <li><strong>5 .Changes to Orders:</strong> Any changes to menu items or service details must be requested at least 1 day before the event. We will do our best to accommodate last-minute changes, but they may be subject to additional charges.</li>
                          </ul>

                          <p>We look forward to making your event memorable! If you have any questions or need further assistance, please do not hesitate to contact us.</p>

                                          
                    </div>
                

                {/* <div className="row">
                  <div className="col-sm-4 col-6">
                    <div className="counter-item style-two counter-text-wrap">
                      <span
                        className="count-text k-plus"
                        data-speed={3000}
                        data-stop={10}
                      >
                        0
                      </span>
                      <span className="counter-title">Organic Planting</span>
                    </div>
                  </div>
                  <div className="col-sm-4 col-6">
                    <div className="counter-item style-two counter-text-wrap">
                      <span
                        className="count-text plus"
                        data-speed={3000}
                        data-stop={5}
                      >
                        0
                      </span>
                      <span className="counter-title">Passionate Chef’s</span>
                    </div>
                  </div>
                  <div className="col-sm-4 col-6">
                    <div className="counter-item style-two counter-text-wrap">
                      <span
                        className="count-text plus"
                        data-speed={3000}
                        data-stop={20}
                      >
                        0
                      </span>
                      <span className="counter-title">Favourite Dishes</span>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            {/* <div className="col-lg-6">
              <div
                className="about-image-four style-two mb-30"
                data-aos="fade-right"
                data-aos-duration={1500}
                data-aos-offset={50}
              >
                <div className="row">
                  <div className="col">
                    <img src="assets/images/about/about-four1.jpg" alt="About us" />
                  </div>
                  <div className="col mt-80">
                    <img src="assets/images/about/about-four2.jpg" alt="About us" />
                  </div>
                </div>
                <div className="badge">
                  <img
                    src="assets/images/about/about-four-badge.jpg"
                    alt="food quanlity"
                  />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      {/* About Us Area end */}
      {/* Headline area start */}
      <div className="headline-area mb-105 rmb-85 rel z-1">
        <span className="marquee-wrap">
          <span className="marquee-inner left">
          <span className="marquee-item">Soup</span>
          <span className="marquee-item">
            <i className="flaticon-star" />
          </span>
          <span className="marquee-item">Appetizers</span>
          <span className="marquee-item">
            <i className="flaticon-star" />
          </span>
          <span className="marquee-item">Tandoor</span>
          <span className="marquee-item">
            <i className="flaticon-star" />
          </span>
          <span className="marquee-item">Gravy</span>
          <span className="marquee-item">
            <i className="flaticon-star" />
          </span>
        </span>

        <span className="marquee-inner left">
          <span className="marquee-item">Soup</span>
          <span className="marquee-item">
            <i className="flaticon-star" />
          </span>
          <span className="marquee-item">Appetizers</span>
          <span className="marquee-item">
            <i className="flaticon-star" />
          </span>
          <span className="marquee-item">Tandoor</span>
          <span className="marquee-item">
            <i className="flaticon-star" />
          </span>
          <span className="marquee-item">Gravy</span>
          <span className="marquee-item">
            <i className="flaticon-star" />
          </span>
        </span>

        <span className="marquee-inner left">
          <span className="marquee-item">Soup</span>
          <span className="marquee-item">
            <i className="flaticon-star" />
          </span>
          <span className="marquee-item">Appetizers</span>
          <span className="marquee-item">
            <i className="flaticon-star" />
          </span>
          <span className="marquee-item">Tandoor</span>
          <span className="marquee-item">
            <i className="flaticon-star" />
          </span>
          <span className="marquee-item">Gravy</span>
          <span className="marquee-item">
            <i className="flaticon-star" />
          </span>
        </span>

       
        </span>
        <div className="headline-shapes">
          <div className="shape one">
            {/* <img src="assets/images/shapes/tomato.png" alt="tomato" /> */}
          </div>
          <div className="shape two">
            {/* <img src="assets/images/shapes/burger.png" alt="burger" /> */}
          </div>
        </div>
      </div>
    
      <Footer/>
    </div>
    {/*End pagewrapper*/}
  </>
  
  
  );
}

export default About;
