import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import splash from './splash.css';

function Splash() {
  const containerStyle = {
    position: 'relative',
    minHeight: '100vh',
    overflow: 'hidden',
    fontFamily: 'DinPro, sans-serif',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };

  const backgroundOverlayStyle = {
    content: '""',
    backgroundImage: "url('assets/bg.jpg')",
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0.2,
    zIndex: -1,
  };

  const logoStyle = {
    width: '175px',
    margin: '0 auto',
    display: 'block',
    paddingTop: '40px',
  };

  const citySelectionStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  };

  const cityStyle = {
    color: '#DE943B',
    fontWeight: '400',
    paddingTop: '30px',
    letterSpacing: '3px',
  };

  const linkStyle = {
    display: 'block',
    color: 'black',
    textDecoration: 'none',
    margin: '10px 0',
    fontSize: '20px',
  };

  const socialIconsStyle = {
    display: 'flex',
    justifyContent: 'center',
    margin: '10px 0',
  };

  const iconStyle = {
    width: '35px',
    height: '35px',
    // backgroundColor: 'black',
    borderRadius: '50%',
    display: 'inline-block',
    lineHeight: '35px',
    margin: '8px',
    // color: 'white',
    textAlign: 'center',
    textDecoration: 'none',
    fontSize: '17px',
  };

  const footerPStyle = {
    // color: 'black',
    fontSize: '12px',
    textAlign: 'center',
    marginBottom: '30px',
  };

  const styles = `
    @media (max-width: 768px) {
      .container {
        flex-direction: column;
        align-items: center;
      }
    }
  `;

  return (
    <div style={containerStyle} className="container-fluid">
      <Helmet>
        <title>Clove Restaurant - Culinary Excellence in Pollachi</title>
        <meta
          name="description"
          content="Welcome to Clove, Pollachi's premier dining destination. Experience a blend of tradition and innovation with our diverse menu."
        />
        <link rel="canonical" href="https://clovefinedine.com/" />
      </Helmet>

      <div style={backgroundOverlayStyle} />

      <div style={{ textAlign: 'center' }}>
        <img src="assets/logo.png" alt="Clove Logo" style={logoStyle} />
      </div>

      <div style={citySelectionStyle} className="cityButton">
        <h5 style={cityStyle}>Choose The City</h5>
        <p style={{ marginBottom: '0px' }}>
          <Link to="/pollachi" className="link textColor" style={{ display: 'block' }}>
            POLLACHI
          </Link>
        </p>
        <p>
          <Link to="/" className="link textColor" style={{ display: 'block' }}>
            COIMBATORE
          </Link>
        </p>
      </div>



      <footer style={{ padding: '0px 0', }}>
      

        <div className="social-icons" style={socialIconsStyle}>
          <a href="https://facebook.com" className="icon" style={iconStyle} aria-label="Facebook" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-facebook-f"></i>
          </a>
          <a href="https://twitter.com" className="icon" style={iconStyle} aria-label="Twitter" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="https://instagram.com" className="icon" style={iconStyle} aria-label="Instagram" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://linkedin.com" className="icon" style={iconStyle} aria-label="LinkedIn" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>




        <p style={footerPStyle}>COPYRIGHT 2024 CLOVE RESTAURANT. ALL RIGHTS RESERVED</p>
      </footer>
    </div>
  );
}

export default Splash;
