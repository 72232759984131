import Header from './components/Header';
import Footer from './components/Footer';
import React, { useEffect } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.min.js';
import $ from 'jquery';


function Coimbatore() {
  const loadScript = (src, callback) => {
    const script = document.createElement('script');
    script.src = src;
    script.onload = callback;
    script.onerror = () => console.error(`Failed to load script: ${src}`);
    document.head.appendChild(script);
  };

  useEffect(() => {


    $('.slider').slick({
      dots: true,
      arrows: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: '<button type="button" class="slick-prev">&lt;</button>',
      nextArrow: '<button type="button" class="slick-next">&gt;</button>',
    });

   

    // List of scripts to load
    const scripts = [
      
      '/assets/js/scriptaaa.js'
    ];

    // Load each script
    scripts.forEach((src, index) => {
      loadScript(src, () => {
        console.log(`Script loaded: ${src}`);
        // Optionally handle initialization here
      });
    });
    window.scrollTo(0, 0);

    // Cleanup function (if needed)
    return () => {
      $('.slider').slick('unslick');
    };
  }, []);

  return (
    <div className="page-wrapper">
     <Header/>

     <div className="form-back-drop" />
     <section
      className="page-banner-area overlay pt-215 rpt-150 pb-160 rpb-120 rel z-1 bgs-cover text-center"
      style={{ backgroundImage: "url(assets/images/background/banner.jpg)" }}
    >
      <div className="container">
        <div className="banner-inner text-white">
          <h1
            className="page-title"
            data-aos="fade-up"
            data-aos-duration={1500}
            data-aos-offset={50}
          >
            coimbatore
          </h1>
          <nav aria-label="breadcrumb">
            <ol
              className="breadcrumb justify-content-center"
              data-aos="fade-up"
              data-aos-delay={200}
              data-aos-duration={1500}
              data-aos-offset={50}
            >
              <li className="breadcrumb-item">
                <a>Home</a>
              </li>
              <li className="breadcrumb-item active">coimbatore</li>
            </ol>
          </nav>
        </div>
      </div>
    </section>
      <div className="container my-4 mt-3 mb-4">
        <div className="row g-4">
             
             Under Construction
         
          {/* <div className="col-12" style={{padding:"150px"}}>
              <div className="slider" >
                <div>
                  <img src="assets/1.jpg" alt="Slide 1"/>
                </div>
               
              </div>
          </div> */}
         
          </div>
      </div>

      
     <Footer />
   </div>
  
  );
}

export default Coimbatore;
